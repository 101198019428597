import { TableWidget, MoreBar, FilterButton } from '@revolut/ui-kit'
import { useTable } from '@components/Table/hooks'
import Stat from '@components/Stat/Stat'
import React from 'react'
import AdjustableTable from '@components/Table/AdjustableTable'
import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import {
  performanceReviewEmployeeColumn,
  performanceReviewReviewerColumn,
  performanceReviewStatusColumn,
  performanceReviewTypeColumn,
} from '@src/constants/columns/performanceReviews'
import {
  PerformanceReviewInterface,
  PerformanceReviewStats,
} from '@src/interfaces/performance'
import { SettingsButton } from '@src/features/SettingsButtons/SettingsButton/SettingsButton'
import { ROUTES } from '@src/constants/routes'
import { settingsConfig } from '@src/pages/Settings/SettingsLandingPage/constants'
import { Statuses } from '@src/interfaces'
import { useHasNewScorecards } from '@src/utils/performance'
import {
  getReviewsUrl,
  getReviewEditUrl,
} from '@src/pages/EmployeeProfile/Preview/Performance/Summary/common'
import { useLocation } from 'react-router-dom'
import { Location } from 'history'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { useSelector } from 'react-redux'
import { selectPermissions, selectUser } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { OnboardingAppScreen } from '@src/pages/OnboardingChecklistV2/components/OnboardingAppScreen'
import {
  getAllProbationReviews,
  getAllProbationReviewsStats,
} from '@src/api/probationReview'
import {
  probationReviewCheckpointColumn,
  probationReviewRoleColumn,
  probationReviewSeniorityColumn,
  probationReviewTeamColumn,
} from '@src/constants/columns/probation'
import { calculateCompletion } from '@src/pages/Performance/Reviews/Reviews'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'

const row = (
  currentUserId: number,
  location: Location<unknown>,
  showNewScorecards: boolean,
): RowInterface<PerformanceReviewInterface> => ({
  linkToForm: data => {
    if (
      (data.status === Statuses.pending || data.status === Statuses.draft) &&
      currentUserId === data.reviewer.id
    ) {
      return navigateTo(
        getReviewEditUrl(
          data.category,
          data.reviewed_employee.id,
          data.reviewed_employee.id,
          data.id,
          showNewScorecards,
        ),
      )
    }

    return navigateTo(
      getReviewsUrl(
        data.category,
        data.cycle,
        data.reviewed_employee.id,
        location,
        showNewScorecards,
      ),
    )
  },
  cells: [
    {
      ...performanceReviewEmployeeColumn,
      width: 200,
    },
    {
      ...performanceReviewTypeColumn,
      width: 100,
    },
    {
      ...probationReviewCheckpointColumn,
      width: 100,
    },
    {
      ...performanceReviewReviewerColumn,
      width: 200,
    },
    {
      ...probationReviewSeniorityColumn,
      width: 100,
    },
    {
      ...probationReviewTeamColumn,
      width: 150,
    },
    {
      ...probationReviewRoleColumn,
      width: 150,
    },
    {
      ...performanceReviewStatusColumn,
      width: 100,
    },
  ],
})

interface ReviewsCommonProps {
  category: 'probation' | 'pip'
}

const ReviewsCommonTable = ({ category }: ReviewsCommonProps) => {
  const showNewScorecards = useHasNewScorecards()
  const location = useLocation()
  const permissions = useSelector(selectPermissions)
  const currentUser = useSelector(selectUser)

  const { data: performanceSettings } = useGetPerformanceSettings()
  const canViewCycles = permissions.includes(PermissionTypes.ViewReviewCycles)
  const probationEnabled = performanceSettings?.enable_probation
  const pipEnabled = performanceSettings?.enable_pip
  const canViewPipeline =
    canViewCycles && (category === 'probation' ? probationEnabled : pipEnabled)

  const initialFilters = [
    {
      columnName: 'category',
      filters: [{ name: category, id: category }],
      nonResettable: true,
    },
    {
      columnName: 'reviewer__id',
      filters: [{ name: currentUser.display_name, id: currentUser.id }],
      nonResettable: true,
    },
  ]

  const table = useTable<PerformanceReviewInterface, PerformanceReviewStats>(
    {
      getItems: getAllProbationReviews,
      getStats: getAllProbationReviewsStats,
    },
    initialFilters,
  )

  const filtersByReviewer =
    table.filterBy.find(filterBy => filterBy.columnName === 'reviewer__id')?.filters || []
  const isFilteredByMyReviews = filtersByReviewer.some(
    filter => filter.id === currentUser.id,
  )

  const toggleFilterByReviews = () => {
    table.onFilterChange(
      isFilteredByMyReviews
        ? { columnName: 'reviewer__id', filters: [] }
        : {
            columnName: 'reviewer__id',
            filters: [{ name: currentUser.display_name, id: currentUser.id }],
          },
    )
  }

  return (
    <TableWidget>
      <TableWidget.Info>
        <Stat label="Review completion" val={calculateCompletion(table.stats)} />
      </TableWidget.Info>
      <TableWidget.Actions>
        <MoreBar>
          {canViewPipeline && (
            <MoreBar.Action
              to={pathToUrl(
                category === 'probation'
                  ? ROUTES.PERFORMANCE.MANAGE_REVIEWS.MANAGE_PROBATION
                  : ROUTES.PERFORMANCE.MANAGE_REVIEWS.MANAGE_PIP,
              )}
              use={InternalLink}
              useIcon="16/ListBullet"
            >
              View pipeline
            </MoreBar.Action>
          )}
          <SettingsButton
            path={
              category === 'probation'
                ? ROUTES.PERFORMANCE.SETTINGS.PROBATION
                : ROUTES.PERFORMANCE.SETTINGS.PIP
            }
            canView={settingsConfig.performance.canView}
          />
        </MoreBar>
      </TableWidget.Actions>
      <TableWidget.Filters>
        <FilterButton active={isFilteredByMyReviews} onClick={toggleFilterByReviews}>
          My reviews
        </FilterButton>
      </TableWidget.Filters>

      <TableWidget.Table>
        <AdjustableTable
          name={TableNames.PerformanceReviews}
          useWindowScroll
          row={row(currentUser.id, location, showNewScorecards)}
          {...table}
          noDataMessage="All performance reviews will appear here"
        />
      </TableWidget.Table>
    </TableWidget>
  )
}

export const ReviewsCommon = ({ category }: ReviewsCommonProps) => {
  return (
    <OnboardingAppScreen category="reviews">
      <ReviewsCommonTable category={category} />
    </OnboardingAppScreen>
  )
}
